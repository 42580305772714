<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <div class="icon" v-html="iconSvg(icon)"></div>
            <h2 class="heading-title">About Us</h2>
            <p class="description">
              Transforming visions into sonic and visual masterpieces – where passion meets precision at Diminished Fifth Studios
            </p>
            <p class="description">
              Welcome to Diminished Fifth Studios, your destination for unparalleled AV and recording studio services.
              We specialize in delivering quality and tailored solutions to bring your audio and visual visions to life.
              Our small but passionate team ensures attention to detail, using cutting-edge technology in our recording
              studio and offering bespoke AV setups. Experience the difference of personalized service and excellence
              with Diminished Fifth Studios. Elevate your sound, enhance your visuals – choose us for a journey beyond expectations.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
